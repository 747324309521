import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Header, Default } from '@components'

const SuccessPage = ({ location }) => {
  const { state } = location
  
  useEffect(() => {
    !state && navigate('/404')
  }, [])

  return state ? <>
    <Header/>
    <Default title="Sua mensagem foi enviada com sucesso." description="Obrigado por entrar em contato."/>
  </> : <></>
}

export default SuccessPage